import React, { useState } from "react";
import Navigation from "./Navigation"; // Import the Navigation component
import { useSelector, useDispatch } from "react-redux"; // Import useSelector and useDispatch hooks
import { signup } from "../redux/slices/authSlice"; // Import the signup action from authSlice

const MemberRegistrationPage = () => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const dispatch = useDispatch(); // Initialize useDispatch hook
  const { signupRes, isLoading, error } = useSelector((state) => state.auth); // Get auth state

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    // Validate email and password
    if (!email) {
      setEmailError('Email is required');
    } else {
      setEmailError('');
    }
    if (!password) {
      setPasswordError('Password is required');
    } else {
      setPasswordError('');
    }
    if (email && password) {
      const userData = { email, password, confirm_password: password };
      dispatch(signup(userData));
    }
  };

  if (signupRes && signupRes.email) {
    window.location.href = `/email-verification?email=${signupRes.email}`;
  }

  return (
    <>
      {/* Navigation Component */}
      <Navigation />

      {/* Registration Form Section */}
      <section className="pt-pb">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 px-lg-4 col-md-5 mx-auto">
              <div className="login-form">
                <h3 className="text-center">Member Registration</h3>
                <form onSubmit={handleSignup}>
                  <div className="mb-3">
                    <input type="email" className="form-control shadow-none" id="email" placeholder="figma@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                    {emailError && <p style={{ color: 'red', fontSize: 'small' }}>{emailError}</p>}
                  </div>
                  <div className="mb-3 position-relative" style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                    {/* Input field */}
                    <input
                      type={isPasswordVisible ? "text" : "password"}
                      className="form-control shadow-none password-field"
                      id="password"
                      placeholder=".........."
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      style={{
                        paddingRight: '40px',   // Add padding to the right to make room for the icon
                        width: '100%',          // Ensure the input field takes full width
                        boxSizing: 'border-box' // Ensure padding and border are included in width calculation
                      }}
                    />
                    {/* Toggle password icon */}
                    <div
                      onClick={togglePasswordVisibility}
                      style={{
                        position: 'absolute',    // Position absolutely relative to the container
                        right: '10px',           // Place it 10px from the right edge of the input
                        top: '50%',              // Center vertically
                        transform: 'translateY(-50%)', // Adjust for perfect centering
                        cursor: 'pointer',
                        zIndex: 10               // Ensure it stays above other elements
                      }}
                    >
                      <img
                        src={isPasswordVisible ? require('../assets/images/show.png') : require('../assets/images/hide.png')}
                        className="img-fluid"
                        alt="Toggle Password"
                        style={{ width: '20px', height: '20px' }} // Adjust the icon size
                      />
                    </div>
                  </div>
                  {passwordError && <p style={{ color: 'red', fontSize: 'small' }}>{passwordError}</p>}
                  <button type="submit" className="btn-green w-100" disabled={isLoading}>
                    {isLoading ? 'Signing up...' : 'Create an Account'}
                  </button>
                  {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}
                  {signupRes && <p style={{ color: 'green' }}>Signup successful!</p>}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer Section */}
      <footer className="sticky-bb">
        <div className="container-fluid px-md-5">
          <div className="py-3 text-center">
            <div className="text">@2024 museums</div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default MemberRegistrationPage;
