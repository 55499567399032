// src/api/fetchHelper.js
import axios from 'axios';

// Helper function to get the Authorization header
const auth = () => {
  const childToken = localStorage.getItem('child_token');
  const token = localStorage.getItem('token');
  return `Bearer ${childToken ? childToken : token}`;
};

// Helper function to build query string from an object
const buildQueryParams = (params) => {
  if (!params) return '';
  const query = new URLSearchParams(params).toString();
  return query ? `?${query}` : '';
};

// Main function to fetch data using Axios
export async function fetchData({
  apiUrl,
  method = 'GET',
  body,
  formData,
  contentType = 'application/json',
  queryParams = {},
}) {
  const requestConfig = {
    url: `${apiUrl}${buildQueryParams(queryParams)}`, // Attach query parameters if provided
    method,
    headers: {
      ...(formData ? {} : { 'Content-Type': contentType }),
      Accept: contentType,
      ...(['/password-reset-confirm', '/signup', '/password-reset', '/login'].some(path => apiUrl.includes(path)) && !apiUrl.includes('/profile/login/') ? {} : { Authorization: auth() }),
    },
    data: formData || (contentType === 'application/json' ? JSON.stringify(body) : body), // Set request data
  };

  try {
    const response = await axios(requestConfig);
    return response.data; // Return the response data
  } catch (error) {
    // Handle errors based on Axios response structure
    const errorMessage = error.response?.data?.message || error.message || 'An error occurred';
    throw new Error(errorMessage);
  }
}
