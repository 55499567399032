import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Menu, X } from 'lucide-react';

const Navigation = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // For desktop hamburger
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // For mobile menu
  const navigate = useNavigate();

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleDesktopModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const logout = () => {
    if (localStorage.getItem('child_token')) {
      localStorage.removeItem('child_token');
      localStorage.removeItem('child_refresh_token');
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
    }
    navigate('/login');
  };

  const mainMenuItems = ["Search Works", "About us", "Blog", "Contact Us"];
  const userMenuItems = ["My Gallery", "Family Gallery", "My Collection", "My Contest", "Artist Class", "Profile", "Payment Information", "Account Information", "Manage Users"];

  const filteredUserMenuItems = localStorage.getItem('child_token')
    ? (localStorage.getItem('token') ? userMenuItems.filter(item => item !== "Account Information" && item !== "Manage Users") : userMenuItems)
    : userMenuItems.filter(item => item === "Account Information" || item === "Manage Users");

  return (
    <>
      <header className="sticky-top sticky-header">
        <div className="container-fluid px-md-5">
          <nav className="navbar">
            <a className="navbar-brand" href="#">
              <img src={require('../assets/images/logo.png')} className="img-fluid" alt="logo" />
            </a>

            {/* Desktop Menu */}
            <div className="desktop-menu">
              <ul className="navbar-nav">
                {mainMenuItems.map((item) => (
                  <li className="nav-item" key={item}>
                    <a className="nav-link active" href={item === "Contact Us" ? "/contact-us" : item === "Search Works" ? "/public-gallery" : "#"}>{item}</a>
                  </li>
                ))}
                {!localStorage.getItem('token') && (
                  <li className="nav-item position-relative">
                    <a className="nav-link" href="/login">Login</a>
                    <a className="nav-link bottom-login-member-name" href="/register">
                      or Member Registration
                    </a>
                  </li>
                )}
                <li className="nav-item desktop-hamburger">
                  <a className="nav-link" href="#" onClick={toggleDesktopModal}>
                    <img src={require('../assets/images/hamburger.png')} className="img-fluid" alt="hamburger" />
                  </a>
                </li>
              </ul>
            </div>

            {/* Mobile Menu Toggle */}
            <button className="mobile-menu-toggle" onClick={toggleMobileMenu}>
              {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>

            {/* Mobile Menu */}
            <div className={`mobile-menu ${isMobileMenuOpen ? 'show' : ''}`}>
              <div className="mobile-menu-content">
                <button className="btn-close" onClick={toggleMobileMenu} aria-label="Close">×</button>
                <div className="profile-info-box">
                  <h3>My Page</h3>
                  <img src={require('../assets/images/logo.svg').default} className="img-fluid rounded-circle main-img" alt="user" />
                  <h4 className="mb-3">user_name_dayo</h4>
                  <div>
                    <h4>Shared users</h4>
                    <div className="shared-users-images">
                      <img src={require('../assets/images/user1.png')} className="img-fluid rounded-circle small-img" alt="user1" />
                      <img src={require('../assets/images/user2.png')} className="img-fluid rounded-circle small-img margin-l-negative" alt="user2" />
                      <img src={require('../assets/images/user3.png')} className="img-fluid rounded-circle small-img margin-l-negative" alt="user3" />
                    </div>
                  </div>
                </div>

                <div className="mobile-action-buttons">
                  <a href="/register-work" className="btn-border-green">Register your work</a>
                  <a href="/public-gallery" className="btn-border-green">Find other users' works</a>
                </div>

                <ul className="mobile-nav-list">
                  {[...mainMenuItems, ...filteredUserMenuItems].map((item) => (
                    <li key={item}>
                      <a href={
                        item === "My Gallery" ? "/my-work" :
                          item === "Manage Users" ? "/add-user" :
                            item === "My Collection" ? "/my-collection" :
                              item === "Contact Us" ? "/contact-us" :
                                item === "Account Information" ? "/account-info" :
                                  item === "Profile" ? "/user-info" :
                                    item === "Search Works" ? "/public-gallery" : "#"
                      }>
                        {item}
                      </a>
                    </li>
                  ))}
                  {!localStorage.getItem('token') && (
                    <li>
                      <a href="/login">Login</a>
                    </li>
                  )}
                  {localStorage.getItem('token') && (
                    <li>
                      <a href="#" onClick={logout}>Logout</a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>

      {/* Desktop Modal */}
      {isModalOpen && (
        <div className="modal show" tabIndex="-1" role="dialog" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-scrollable modal-dialog-slide modal-dialog-right" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <button type="button" className="btn-close" onClick={toggleDesktopModal} aria-label="Close">×</button>
                <div className="profile-info-box">
                  <h3>My Page</h3>
                  <img src={require('../assets/images/logo.svg').default} className="img-fluid rounded-circle main-img" alt="user" />
                  <h4 className="mb-3">user_name_dayo</h4>
                  <div>
                    <h4>Shared users</h4>
                    <div>
                      <img src={require('../assets/images/user1.png')} className="img-fluid rounded-circle small-img" alt="user1" />
                      <img src={require('../assets/images/user2.png')} className="img-fluid rounded-circle small-img margin-l-negative" alt="user2" />
                      <img src={require('../assets/images/user3.png')} className="img-fluid rounded-circle small-img margin-l-negative" alt="user3" />
                    </div>
                  </div>
                </div>
                <hr />
                <a href="/register-work" className="btn-border-green mb-3">Register your work</a>
                <a href="/public-gallery" className="btn-border-green mb-3">Find other users' works</a>
                <div>
                  <ul>
                    {filteredUserMenuItems.map((item) => (
                      <li key={item}>
                        <a
                          href={
                            item === "My Gallery" ? "/my-work" :
                              item === "Manage Users" ? "/add-user" :
                                item === "My Collection" ? "/my-collection" :
                                  item === "Contact Us" ? "/contact-us" :
                                    item === "Account Information" ? "/account-info" :
                                      item === "Profile" ? "/user-info" :
                                        item === "Search Works" ? "/public-gallery" : "#"
                          }
                        >
                          {item}
                        </a>
                        <div>
                          <img src={require('../assets/images/angle-right.png')} className="img-fluid" alt="angle-right" />
                        </div>
                      </li>
                    ))}
                    {localStorage.getItem('token') && (
                      <li>
                        <a href="#" onClick={logout}>Logout</a>
                        <div>
                          <img src={require('../assets/images/angle-right.png')} className="img-fluid" alt="angle-right" />
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Navigation;
