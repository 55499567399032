import React, { useEffect, useState } from "react";
import Navigation from "./Navigation"; // Import the Navigation component

const EmailVerification = () => {
  const [email, setEmail] = useState('');
  const [isPass, setIsPass] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const emailParam = urlParams.get('email');
    const isPassParam = urlParams.get('is_pass');
    if (emailParam) {
      setEmail(emailParam);
    }
    if (isPassParam) {
      setIsPass(isPassParam);
    }
  }, []);

  return (
    <>
      <Navigation />
      <div className="verification-container">
        <div className="alert-icon">!</div>  {/* Circle with exclamation mark inside */}
        <h2>{isPass ? "Please complete the password reset verification." : "Please complete the email verification."}</h2>
        <p className="email-address">{email}</p>
        <p className="verification-text">
          {isPass ? (
            <>
              Your password reset is not yet complete. <br />
              A confirmation email has been sent to your registered email address. <br />
              Please click link to reset your password.
            </>
          ) : (
            <>
              Your account registration is not yet complete. <br />
              A confirmation email has been sent to your registered email address. <br />
              Please register for authentication from the confirmation email.
            </>
          )}
        </p>

        {/* <a href="/forgot-password" className="forgot-password-link">
        Forgot your password?
      </a> */}
      </div>
    </>
  );
};

export default EmailVerification;
