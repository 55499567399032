import React, { useState } from "react";
import Navigation from "./Navigation";
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../redux/slices/authSlice';
import { FE_BASE_URL } from "../constants";

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const { resetPasswordRes, error } = useSelector(state => state.auth);

  const handleResetPassword = async () => {
    if (!email) {
      alert('Password is required');
      return;
    }
    const response = await dispatch(resetPassword(email));
    if (resetPasswordRes?.detail) {
      window.location.href = `${process.env.REACT_APP_BASE_FE_URL}/email-verification?email=${email}&is_pass=true`;
    }
  };

  return (
    <>
    <Navigation/>
    <div className="password-reset-container">
      <h2 className="password-reset-title">Password reset</h2>
      <p className="password-reset-instructions">
        After entering your e-mail address, you will be authenticated.<br />
        Please register for authentication from the confirmation email.
      </p>
      <input 
        type="email" 
        className="password-reset-email-input" 
        placeholder="figma@example.com" 
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <button className="password-reset-submit-button" onClick={handleResetPassword} disabled={!email}>
        Change your password
      </button>
      {/* {resetPasswordRes && <p>Password reset email sent successfully.</p>} */}
      {/* {error && <p>Error: {error}</p>} */}
    </div>
    </>
  );
};

export default ForgotPassword;
