// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import userReducer from './slices/userSlice';
import workReducer from './slices/workSlice';
const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    work: workReducer,
  },
});

export default store;
