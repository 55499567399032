import React, { useState } from 'react';
import { Upload } from 'lucide-react';
import Navigation from './Navigation';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const ContestApplyPage = () => {
  const [images, setImages] = useState([]);

  const validationSchema = Yup.object({
    title: Yup.string().required('Title is required'),
    comments: Yup.string().required('Comments are required'),
    images: Yup.array().min(1, 'At least one image is required'),
    category: Yup.string().required('Category is required'),
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      comments: '',
      category: '',
      isIndicated: false,
      images: []
    },
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
    }
  });

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 5) {
      alert('You can only upload up to 5 images');
      return;
    }
    setImages(files);
    formik.setFieldValue('images', files);
  };

  return (
    <>
      <Navigation />
      <div className="contest-detail-container">
        <div className="contest-detail-content">
          {/* Contest Info Section */}
          <div className="contest-detail-header">
            <div className="contest-detail-image">
              <img src="/contest-image.jpg" alt="Contest banner" />
            </div>
            <div className="contest-detail-info">
              <div className="contest-detail-user">
                <img src="/user-avatar.jpg" alt="Organization" />
                <span>organization_username</span>
              </div>
              <h1 className="contest-detail-title">
                This is the title of the mountain contest. Contest Title.
              </h1>
              <p className="contest-detail-date">October 1, 2024 - December 31, 2024</p>
              
              <div className="contest-detail-buttons">
                <button className="contest-apply-button">
                  Apply through My Gallery
                </button>
                <button className="contest-upload-button">
                  Upload and submit your work
                </button>
              </div>

              <div className="contest-detail-description">
                <p>Description of the work Description of the work...</p>
              </div>

              <div className="contest-eligibility">
                <h3>Eligibility for Application</h3>
                <p>Individuals over 12 years old</p>
              </div>
            </div>
          </div>

          {/* Upload Form Section */}
          <div className="contest-upload-section">
            <h2 className="contest-upload-title">Upload and enter this contest</h2>
            
            <form onSubmit={formik.handleSubmit} className="contest-upload-form">
              <div className="contest-form-group">
                <label>Image of the work</label>
                <div className="contest-upload-container">
                  <input
                    type="file"
                    multiple
                    accept="image/*"
                    onChange={handleImageUpload}
                    className="contest-file-input"
                    id="image-upload"
                  />
                  <label htmlFor="image-upload" className="contest-upload-area">
                    <Upload className="contest-upload-icon" />
                    <span className="contest-upload-text">Upload Image</span>
                    <span className="contest-upload-limit">
                      (up to a maximum of 5 pieces)
                    </span>
                  </label>
                </div>
              </div>

              <div className="contest-form-group">
                <label>Title</label>
                <input
                  type="text"
                  placeholder="Title of work"
                  {...formik.getFieldProps('title')}
                  className="contest-input"
                />
              </div>

              <div className="contest-form-group">
                <label>Comments on the work</label>
                <textarea
                  placeholder="Please write your comments on the artwork"
                  {...formik.getFieldProps('comments')}
                  className="contest-textarea"
                />
              </div>

              <div className="contest-form-group">
                <label>publish settings</label>
                <div className="contest-toggle-group">
                  <label className="contest-toggle">
                    <input
                      type="checkbox"
                      {...formik.getFieldProps('isIndicated')}
                    />
                    <span className="contest-slider"></span>
                  </label>
                </div>
              </div>

              <div className="contest-form-group">
                <label>Category Settings</label>
                <select
                  {...formik.getFieldProps('category')}
                  className="contest-select"
                >
                  <option value="">All Categories</option>
                </select>
              </div>

              <button type="submit" className="contest-submit-button">
                Submit a work with this content
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContestApplyPage;
