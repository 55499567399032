import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight, Heart } from 'lucide-react';
import Navigation from './Navigation';
import { useLocation, useNavigate } from 'react-router-dom';

const GalleryDetail = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const [work, setWork] = useState(location.state?.work || null);

  useEffect(() => {
    if (!location.state || !location.state.work) {
      navigate('/my-work'); // Redirect to my work if work is null or undefined
    }
  }, [location.state, navigate]);

  const getInitials = (email) => {
    const [name] = email.split('@');
    return name.slice(0, 2).toUpperCase();
  };

  if (!work) {
    return null; // Render nothing if work is null or undefined
  }

  return (
    <>
      <Navigation />
      <div className="gallery-detail-container">
        <h1 className="gallery-detail-header">gallery</h1>

        <div className="gallery-detail-content">
          <div className="gallery-detail-image-section">
            <div className="gallery-detail-main-image">
              {work.images_data.length > 1 && (
                <button
                  className="gallery-nav-button left"
                  onClick={() => setCurrentImageIndex(prev =>
                    prev === 0 ? work.images_data.length - 1 : prev - 1
                  )}
                >
                  <ChevronLeft />
                </button>
              )}

              {work.images_data[currentImageIndex] && (
                <img
                  src={work.images_data[currentImageIndex].image_url}
                  alt={work.title}
                />
              )}

              {work.images_data.length > 1 && (
                <button
                  className="gallery-nav-button right"
                  onClick={() => setCurrentImageIndex(prev =>
                    prev === work.images_data.length - 1 ? 0 : prev + 1
                  )}
                >
                  <ChevronRight />
                </button>
              )}
            </div>

            {work.images_data.length > 1 && (
              <div className="gallery-detail-thumbnails">
                {work.images_data.map((image, index) => (
                  <img
                    key={image.id}
                    src={image.image_url}
                    alt={`Thumbnail ${index + 1}`}
                    className={`thumbnail ${index === currentImageIndex ? 'active' : ''}`}
                    onClick={() => setCurrentImageIndex(index)}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="gallery-detail-info">
            <div className="gallery-detail-author">
              <div className={`author-avatar ${!work.member.profile_picture ? 'user-avatar-initial' : ''}`}>
                {work.member.profile_picture ? (
                  <img src={work.member.profile_picture} alt={work.member.username} />
                ) : (
                  getInitials(work.member.username)
                )}
              </div>
              <span className="author-name">{work.member.username}</span>
            </div>

            <h2 className="gallery-detail-title">{work.title}</h2>

            <div className="gallery-detail-likes">
              <Heart
                className={`gallery-heart-icon ${work.is_liked_by_user ? 'active' : ''}`}
                size={20}
              />
              <span>{`${work.likes_count}`}</span>
            </div>

            {work.description && (
              <p className="gallery-detail-description">{work.description}</p>
            )}

            {work.category?.name && (
              <div className="gallery-detail-category">
                <h3>Category</h3>
                <p>{work.category.name}</p>
              </div>
            )}

            <div className="gallery-detail-tags">
              <h3>tag</h3>
              <div className="tag-list-detail">
                {work.tags && work.tags.length > 0 ? (
                  work.tags.map((tag) => (
                    <span key={tag.id} className="tag-detail">
                      {tag.name}
                    </span>
                  ))
                ) : (
                  <span className="tag-detail">N/A</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GalleryDetail;
