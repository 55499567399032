import React from 'react';
import { Search } from 'lucide-react';
import Navigation from './Navigation';

const ContestList = () => {
  const dummyContests = [
    {
      id: 1,
      image: '/handmade-contest.jpg',
      title: 'This is the title of the mountain contest. Contest Title.',
      date: 'October 1, 2024 - December 31, 2024',
      user: 'user_name_dayo',
      userImage: '/user-avatar.jpg',
      type: 'ongoing'
    },
    {
      id: 2,
      image: '/oil-drawing-contest.jpg',
      title: 'Mountain Contest Title. Contest Title.',
      date: 'October 1, 2024 - December 31, 2024',
      user: 'user_name_dayo',
      userImage: '/user-avatar.jpg',
      type: 'ongoing'
    },
    {
      id: 3,
      image: '/oil-drawing-contest-2.jpg',
      title: 'This is the title of the mountain contest. Contest Title.',
      date: 'October 1, 2024 - December 31, 2024',
      user: 'user_name_dayo',
      userImage: '/user-avatar.jpg',
      type: 'completed'
    }
  ];

  return (
    <>
      <Navigation />
      <div className="contest-container">
  <div className="contest-search-wrapper">
    <div className="contest-search-section">
      <div className="contest-search-container">
        <Search className="contest-search-icon" />
        <input 
          type="text" 
          placeholder="Search by free word"
          className="contest-search-input"
        />
      </div>

      <select className="contest-dropdown">
        <option value="">All Categories</option>
      </select>

      <input 
        type="text" 
        placeholder="Tag Search"
        className="contest-tag-input"
      />

      <button className="contest-search-button">
        Narrow down your search by these criteria
      </button>
    </div>
  </div>
  
        {/* Ongoing Contests Section */}
        <div className="contest-section">
    <h2 className="contest-section-title">Scheduled and ongoing contests</h2>
    {dummyContests.filter(contest => contest.type === 'ongoing').map(contest => (
      <div key={contest.id}>
        <div className="contest-card">
          <div className="contest-image-container">
            <img src={contest.image} alt={contest.title} className="contest-image" />
            <div className="contest-user">
              <img src={contest.userImage} alt={contest.user} className="contest-user-image" />
              <span className="contest-username">{contest.user}</span>
            </div>
          </div>
          <div className="contest-info">
            <h3 className="contest-title">{contest.title}</h3>
            <p className="contest-date">{contest.date}</p>
            <button className="contest-details-button">Details.</button>
          </div>
        </div>
      </div>
    ))}
  </div>


        {/* Completed Contests Section */}
        <div className="contest-section">
    <h2 className="contest-section-title">Contests already held (results announced)</h2>
    {dummyContests.filter(contest => contest.type === 'completed').map(contest => (
      <div key={contest.id}>
        <div className="contest-card">
          <div className="contest-image-container">
            <img src={contest.image} alt={contest.title} className="contest-image" />
            <div className="contest-user">
              <img src={contest.userImage} alt={contest.user} className="contest-user-image" />
              <span className="contest-username">{contest.user}</span>
            </div>
          </div>
          <div className="contest-info">
            <h3 className="contest-title">{contest.title}</h3>
            <p className="contest-date">{contest.date}</p>
            <button className="contest-details-button">Details.</button>
          </div>
        </div>
      </div>
    ))}
  </div>


        <button className="contest-see-more">See more</button>
      </div>
    </>
  );
};

export default ContestList;
