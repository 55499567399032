export const APP_TITLE = process.env.REACT_PUBLIC_TITLE;
export const BASE_URL = process.env.REACT_APP_BASE_BE_URL;
export const FE_BASE_URL = process.env.REACT_APP_BASE_FE_URL
export const USER_STATUS_COLOR_MAPPING = {
  active: "#94BA54",
  inactive: "#F25F5C"
};
export const USER_STATUS = [
  { title: "ACTIVE", value: "Active" },
  { title: "INACTIVE", value: "Inactive" },
];
export const REPORT_STATUS_COLOR_MAPPING = {
  accepted: "#94BA54",
  pending: "#F25F5C"
};
