import React, { useState, useEffect } from "react";
import Navigation from "./Navigation";
import { useSelector, useDispatch } from "react-redux";
import { registerChild } from "../redux/slices/userSlice"; // Import the registerChild action from userSlice

const UserRegistration = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [currentAddress, setCurrentAddress] = useState('');
  const [realName, setRealName] = useState('');
  const [username, setUsername] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [previewImage, setPreviewImage] = useState(''); // State to hold the preview image URL
  const [currentAddressError, setCurrentAddressError] = useState('');
  const [realNameError, setRealNameError] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [dateOfBirthError, setDateOfBirthError] = useState('');

  const dispatch = useDispatch(); // Initialize useDispatch hook
  const { registerChildRes, isLoading, error } = useSelector((state) => state.user); // Get user state

  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files).slice(0, 5); // Limit to 5 files
    setSelectedFiles(files);
    // Assuming the first file is the profile picture and we want to preview it
    if (files.length > 0) {
      const reader = new FileReader();
      reader.onload = (e) => setPreviewImage(e.target.result);
      reader.readAsDataURL(files[0]);
    }
  };
  const handleRegisterChild = async () => {
    // Validate all fields
    if (!currentAddress) {
      setCurrentAddressError('Current address is required');
    } else {
      setCurrentAddressError('');
    }
    if (!realName) {
      setRealNameError('Real name is required');
    } else {
      setRealNameError('');
    }
    if (!username) {
      setUsernameError('Username is required');
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(username)) {
      setUsernameError('Username must be an email');
    } else {
      setUsernameError('');
    }
    if (!dateOfBirth) {
      setDateOfBirthError('Date of birth is required');
    } else {
      setDateOfBirthError('');
    }

    if (currentAddress && realName && username && dateOfBirth && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(username)) {
      const formData = new FormData();
      formData.append('address', currentAddress);  // Changed 'currentAddress' to 'address'
      formData.append('username', username);          // Changed 'realName' to 'email'
      // Assuming dateOfBirth is in the format YYYY-MM-DD
      formData.append('date_of_birth', dateOfBirth); // Changed 'dateOfBirth' to 'date_of_birth'
  
      // Assuming selectedFiles contains a single file for profile picture
      if (selectedFiles.length > 0) {
        formData.append('profile_picture', selectedFiles[0]); // Use 'profile_picture' as the field name
      }
  
      dispatch(registerChild(formData));  // Send the formData to the backend
    }
  };
  

  // Reset form on successful registration
  React.useEffect(() => {
    if (registerChildRes?.email) {
      setSelectedFiles([]);
      setCurrentAddress('');
      setRealName('');
      setUsername('');
      setDateOfBirth('');
      setPreviewImage(''); // Reset preview image on successful registration
      setCurrentAddressError('');
      setRealNameError('');
      setUsernameError('');
      setDateOfBirthError('');
    }
  }, [registerChildRes]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'currentAddress':
        setCurrentAddress(value);
        setCurrentAddressError('');
        break;
      case 'realName':
        setRealName(value);
        setRealNameError('');
        break;
      case 'username':
        setUsername(value);
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
          setUsernameError('Username must be an email');
        } else {
          setUsernameError('');
        }
        break;
      case 'dateOfBirth':
        setDateOfBirth(value);
        setDateOfBirthError('');
        break;
      default:
        break;
    }
  };

  return (
    <>
    <Navigation/>
    <div className="form-container">
      <h2 className="form-title">User Registration</h2>

      <div className="form-group">
        <label className="form-label">current address</label>
        <input type="text" className="form-input" placeholder="Enter your address" value={currentAddress} name="currentAddress" onChange={handleInputChange} />
        <small className="form-hint">administrative divisions of Japan: Tokyo-to, Hokkai-do, Osaka-fu and Kyoto-fu</small>
        {currentAddressError && <p style={{ color: 'red', fontSize: 'small' }}>{currentAddressError}</p>}
      </div>

      <div className="form-group">
        <label className="form-label">real name</label>
        <input type="text" className="form-input" placeholder="Enter real name" value={realName} name="realName" onChange={handleInputChange} />
        <small className="form-hint">maiden name</small>
        {realNameError && <p style={{ color: 'red', fontSize: 'small' }}>{realNameError}</p>}
      </div>

      <div className="form-group">
        <label className="form-label">username</label>
        <input type="text" className="form-input" placeholder="Enter email" value={username} name="username" onChange={handleInputChange} />
        {usernameError && <p style={{ color: 'red', fontSize: 'small' }}>{usernameError}</p>}
      </div>

      <div className="form-group">
        <label className="form-label">Date of birth</label>
        <div className="dob-inputs">
          <input type="date" className="dob-select" value={dateOfBirth} name="dateOfBirth" onChange={handleInputChange} />
        </div>
        {dateOfBirthError && <p style={{ color: 'red', fontSize: 'small'}}>{dateOfBirthError}</p>}
      </div>

      <div className="form-group icon-setting">
        <label className="form-label">Icon Setting</label>
        <div className="upload-box" onClick={() => document.getElementById("fileInput").click()}>
          <span className="upload-text">Upload Image<br />( up to a maximum of 5 pieces )</span>
          {previewImage && <img src={previewImage} alt="Preview" style={{ width: '100%', height: 'auto' }} />}
        </div>
        <input
          id="fileInput"
          type="file"
          multiple
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleFileUpload}
        />
      </div>

      <button className="submit-button" onClick={handleRegisterChild} disabled={isLoading}>
        {isLoading ? 'Registering...' : 'Register'}
      </button>
      {/* {registerChildRes?.email && setTimeout(() => <p style={{ color: 'green' }}>Registration successful!</p>, 200)}
      {error && setTimeout(() => <p style={{ color: 'red' }}>{error}</p>, 200)} */}
    </div>
    </>
  );
};

export default UserRegistration;
