import React, { useEffect, useState } from "react";
import { ReactComponent as PlusIcon } from '../assets/images/plus-icon.svg'; // You will need an SVG plus icon
import Navigation from "./Navigation";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getProfiles, childProfileLogin } from '../redux/slices/userSlice';

const AddUserScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { profilesRes } = useSelector((state) => state.user);
  const [selectedProfile, setSelectedProfile] = useState(null);

  useEffect(() => {
    dispatch(getProfiles());
  }, [dispatch]);

  // Function to generate initials from the email or name
  const generateInitials = (email) => {
    const namePart = email.split('@')[0]; // Take the part before '@'
    const initials = namePart.charAt(0).toUpperCase(); // Take the first character
    return initials;
  };

  // Function to generate random color
  const getRandomColor = () => {
    const colors = ['#A3BE8C', '#B48EAD', '#EBCB8B', '#D08770', '#88C0D0', '#5E81AC'];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  // Function to handle child login
  const handleChildLogin = async (profile) => {
    try {
      setSelectedProfile(profile.id);
      // Add a slight delay to show the selection effect before navigation
      setTimeout(async () => {
        await dispatch(childProfileLogin({ username: profile.username })).unwrap();
        navigate('/public-gallery');
      }, 300);
    } catch (error) {
      console.error('Failed to login as child:', error);
      setSelectedProfile(null);
    }
  };

  return (
    <>
      <Navigation />

      {profilesRes && profilesRes.length > 0 ? (
        <div className="user-selection-screen">
          <div className="user-profile-container">
            <div className="profile-picture-container">
              {profilesRes[0].profile_picture ? (
                <img src={profilesRes[0].profile_picture} alt={profilesRes[0].email} className="profile-picture" />
              ) : (
                <div
                  className="initials-container"
                  style={{ backgroundColor: getRandomColor() }}
                >
                  {generateInitials(profilesRes[0].email)}
                </div>
              )}
            </div>
            <p className="profile-name">{profilesRes[0].email.split('@')[0]}</p>
          </div>

          <h3 className="select-add-user-text">Select or add users</h3>

          <div className="user-list">
            {profilesRes.map((profile) => (
              <div 
                key={profile.id} 
                className={`user-item ${selectedProfile === profile.id ? 'selected' : ''}`}
                onClick={() => handleChildLogin(profile)}
                style={{
                  transform: selectedProfile === profile.id ? 'scale(1.05)' : 'scale(1)',
                  transition: 'all 0.3s ease',
                  border: selectedProfile === profile.id ? '2px solid #5E81AC' : 'none',
                  boxShadow: selectedProfile === profile.id ? '0 0 10px rgba(94, 129, 172, 0.5)' : 'none'
                }}
              >
                <div className="profile-picture-container">
                  {profile.profile_picture ? (
                    <img
                      src={profile.profile_picture}
                      alt={profile.email}
                      className="child-profile-picture"
                    />
                  ) : (
                    <div
                      className="initials-container"
                      style={{ backgroundColor: getRandomColor() }}
                    >
                      {generateInitials(profile.email)}
                    </div>
                  )}
                </div>
                <p className="child-name">{profile.email.split('@')[0]}</p>
              </div>
            ))}

            {/* Add new user button */}
            <div className="user-item add-user-button" onClick={() => navigate('/register-user')}>
              <div className="add-user-button">
                <PlusIcon className="plus-icon" />
              </div>
              {/* <p className="add-user-text">Add new user</p> */}
            </div>
          </div>
        </div>
      ) : (
        <div className="user-info-container">
          <h2 className="user-info-title">Then enter your user information.</h2>

          <div className="add-user-button" onClick={() => navigate('/register-user')}>
            <PlusIcon className="plus-icon" />
          </div>

          <p className="add-user-text">Adding new users</p>
        </div>
      )}
    </>
  );
};

export default AddUserScreen;
